import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'
import { css } from '@emotion/core'
import { Flex, Box } from 'rebass'

import Layout from '../components/Layout'
import { mq } from '../theme'
import Slant from '../components/Slant'
import TapToCallCta from '../components/TapToCallCta'
import ServicesSection from '../components/ServicesSection'
import TrustRatingSection from '../components/TrustRatingSection'
import TestimonialsSection from '../components/TestimonialsSection'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Header = ({ image, preHeading, heading, headingPoints }) => (
  <div
    css={css`
      background-image: linear-gradient(
          0deg,
          rgba(43, 43, 43, 0.59),
          rgba(43, 43, 43, 0.59)
        ),
        url(${image});
      background-size: cover;
      background-position: center;
    `}
  >
    <Box
      variant="container"
      css={css`
        height: 100vh;
        min-height: 500px;
      `}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        css={css`
          height: 100%;
          color: #fff;
        `}
      >
        <h1
          css={css`
            margin: 0;
            font-size: 80px;
            line-height: 120%;
            text-shadow: 1px 1px 0px #004aad;
            text-align: center;

            ${mq.md} {
              font-size: 100px;
              line-height: 120px;
            }

            ${mq.xl} {
              font-size: 168px;
              line-height: 168px;
            }
          `}
        >
          {preHeading && (
            <span
              css={css`
                display: inline-block;
                font-size: 78px;
                line-height: 78px;
                background: rgba(0, 74, 173, 0.76);
                border: 1px solid #ffffff;
                padding: 15px 18px;
                text-transform: uppercase;
              `}
            >
              {preHeading}
            </span>
          )}{' '}
          <span
            css={css`
              display: block;
            `}
          >
            {heading}
          </span>
        </h1>

        {!!headingPoints.length && (
          <ul
            css={css`
              margin: 0;
              padding: 0;
              border-top: 1px solid;
              border-bottom: 1px solid;

              ${mq.md} {
                padding: 10px 0;
                margin-top: 10px;
              }
            `}
          >
            {headingPoints.map((point, idx) => (
              <li
                key={idx}
                css={css`
                  display: inline-block;
                  font-size: 28px;
                  font-weight: normal;
                  text-shadow: 1px 1px 0px #004aad;

                  ${mq.md} {
                    font-size: 50px;
                    font-weight: bold;
                    line-height: 120%;
                  }
                `}
              >
                {point}
                {idx + 1 !== headingPoints.length && (
                  <svg
                    className="round-dot"
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={css`
                      margin: 0 5px;
                      height: 18px;

                      ${mq.md} {
                        height: 25px;
                        margin: 0 10px;
                      }
                    `}
                  >
                    <circle cx="3" cy="3" r="3" fill="white" />
                  </svg>
                )}
              </li>
            ))}
          </ul>
        )}
      </Flex>
    </Box>
  </div>
)

export const IndexPageTemplate = ({
  header,
  servicesSection,
  testimonialsSection,
}) => {
  const image = header.image.childImageSharp.fluid.originalImg

  return (
    <>
      <Header
        image={image}
        preHeading={header.preHeading}
        heading={header.heading}
        headingPoints={header.headingPoints}
      />

      <ServicesSection {...servicesSection} />
      <TrustRatingSection />
      <TestimonialsSection {...testimonialsSection} />

      <TapToCallCta telephone="07834230718" />

      <Slant last>
        <Box variant="container">
          <Flex
            flexDirection="column"
            css={css`
              margin: 0 auto;
              max-width: 428px;
              text-align: center;
            `}
          >
            <OutboundLink
              href="tel:07834230718"
              css={css`
                font-weight: bold;
                font-size: 32px;
                line-height: 120%;
                color: #fff;
                text-decoration: none;
                margin-bottom: 60px;

                ${mq.md} {
                  font-size: 48px;
                }
              `}
              eventCategory="call_us_cta"
              eventLabel="Footer free estimate call to action"
              eventAction="Tap"
            >
              For a FREE estimate <br />
              Call us on 07834 230718
            </OutboundLink>
            <span
              css={css`
                color: #fff;
              `}
            >
              Copyright &copy; 2020 GM Mobile Mechanics &bull; Website{' '}
              <a
                css={css`
                  color: #fff;
                `}
                href="https://danielgregory.dev"
                rel="noopener nofollow"
              >
                DGD
              </a>
            </span>
          </Flex>
        </Box>
      </Slant>
    </>
  )
}

IndexPageTemplate.propTypes = {
  header: PropTypes.shape({
    preHeading: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.object,
    headingPoints: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const {
    frontmatter: {
      header,
      trustRating,
      servicesSection,
      testimonialsSection,
      seo,
    },
  } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" value={seo.description} />
      </Helmet>
      <IndexPageTemplate
        header={header}
        servicesSection={servicesSection}
        testimonialsSection={testimonialsSection}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  fragment mainHeader on MarkdownRemarkFrontmatter {
    header {
      preHeading
      heading
      headingPoints
      image {
        childImageSharp {
          fluid {
            originalImg
          }
        }
      }
    }
  }

  fragment trustRating on MarkdownRemarkFrontmatter {
    trustRating {
      heading
      description
      image {
        publicURL
      }
    }
  }

  fragment servicesSection on MarkdownRemarkFrontmatter {
    servicesSection {
      heading
      description
      image {
        publicURL
      }
    }
  }

  fragment testimonialSection on MarkdownRemarkFrontmatter {
    testimonialsSection {
      heading
      description
      testimonials {
        content
        author
        source
      }
      image {
        publicURL
      }
    }
  }

  fragment seo on MarkdownRemarkFrontmatter {
    seo {
      title
      description
    }
  }

  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        ...mainHeader
        ...trustRating
        ...servicesSection
        ...testimonialSection
        ...seo
      }
    }
  }
`
